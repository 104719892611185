import React from 'react';
import './List.css';

const List = (props) => {
  return ( 
    <ul className={props.selectable ? 'selectable' : ''}>
      {props.children}
    </ul>
  );
}
 
export default List;