import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-tiny-toast';
import ReactGA from 'react-ga';

import Nav from './common/Nav';
import NavLinks from './common/NavLinks';
import Home from './home/Home';
import Audio from './audio/Audio';
import AudioCollection from './audio/AudioCollection';
import Minecraft from './minecraft/Minecraft';
import Contact from './contact/Contact';
import Footer from './common/Footer';

import './App.css';

ReactGA.initialize('UA-133007359-1');
ReactGA.set({ anonymizeIp: true });

class App extends Component {
  componentDidMount = () => ReactGA.pageview(window.location.pathname + window.location.search)
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search)

  render() {
    return (
      <Router>
        <React.Fragment>
          <Nav>
            <NavLinks />
          </Nav>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/audio" component={Audio} />
            <Route exact path="/audio/:name" component={AudioCollection} />
            <Route exact path="/minecraft" component={Minecraft} />
            <Route path="/contact" component={Contact} />
          </Switch>
          <Footer />
          <ToastContainer/>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
