import React, { Component } from 'react';
import { toast } from 'react-tiny-toast';
import {
  Loader,
  Paper
} from '../common/Common';
import { isNullOrEmpty, isEmailValid } from '../services/validationService';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      name: '',
      email: '',
      comment: '',
      nameIsValid: false,
      emailIsValid: false,
      commentIsValid: false,
      nameErrorMessage: '',
      emailErrorMessage: '',
      commentErrorMessage: ''
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
  }
  
  onNameChange(e) {
    let isValid = true;
    let errorMessage = '';

    if (isNullOrEmpty(e.target.value) === true) {
      errorMessage = 'Please enter a name'
      isValid = false;
    }
      
    this.setState({
      name: e.target.value,
      nameIsValid: isValid,
      nameErrorMessage: errorMessage
    })
  }

  onEmailChange(e) {
    let isValid = true;
    let errorMessage = '';

    if (isNullOrEmpty(e.target.value) === true) { 
      errorMessage = 'Please enter a email'
      isValid = false
    }
    else if (isEmailValid(e.target.value) === false) {
      errorMessage = 'Please enter a valid email'
      isValid = false 
    }

    this.setState({
      email: e.target.value,
      emailIsValid: isValid,
      emailErrorMessage: errorMessage
    })
  }

  onCommentChange(e) {
    this.setState({ comment: e.target.value })
  }

  async onSubmit(e) {
    this.setState({isBusy: true})
    e.preventDefault();

    if (this.state.nameIsValid && this.state.emailIsValid) {

      const data = JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        comment: this.state.comment
      })          

      try {        
        const response = await fetch("https://fabric.hentamine.com/api/contact/hello", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: data
        });
                
        if (response.status === 200) {          
          toast.show(
            "Thank you for your enquiry. We'll be in touch soon. :)",
            { position: 'bottom-center', className: 'toast-success', variant: 'success', uniqueCode: 'contact-success-200' })
          
          this.setState({
            name: '',
            email: '',
            comment: ''
          })
        }
        else if (response.status === 400) {
          toast.show(
            "Oh no, it looks like the form hasn't been filled correctly, please ensure you correctly entered all fields",
            { position: 'bottom-center', className: 'toast-error', variant: 'error', uniqueCode: 'contact-error-400' })
        }
        else {
          toast.show(
            "Whoops! Something is wrong on our end, please try again later or contact us directly at hello@hentamine.com",
            { position: 'bottom-center', className: 'toast-info', uniqueCode: 'contact-error-500' })          
        }
      }
      catch (error) {        
        toast.show(
          "Whoops! Something is wrong on our end, please try again later or contact us directly at hello@hentamine.com",
          { position: 'bottom-center', className: 'toast-info', uniqueCode: 'error' }
        )          
      }
      
      this.setState({ isBusy: false })
    }
  }

  render() {
    return (
      <Paper style={{paddingTop: '1rem'}}>
        <form style={{display: 'flex', flexDirection: 'column'}}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" onChange={(e) => this.onNameChange(e)} value={this.state.name} />
            {this.state.nameIsValid === false ? <div className='error-text'>{this.state.nameErrorMessage}</div> : null}
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input type="text" id="email" onChange={(e) => this.onEmailChange(e)} value={this.state.email} />
            {this.state.emailIsValid === false ? <div className='error-text'>{this.state.emailErrorMessage}</div> : null}            
          </div>
          <div className="field">
            <label htmlFor="comment">Comment</label>
            <textarea id="comment" onChange={(e) => this.onCommentChange(e)} value={this.state.comment}></textarea>            
          </div>
          <button
            type="submit"
            className="button primary"
            style={{ alignSelf: 'center' }}
            onClick={(e) => this.onSubmit(e)}>            
            {this.state.isBusy ? 
              <Loader/> :
              "Submit"
            }
          </button>
        </form>
      </Paper>
    );
  }
}

export default ContactForm;