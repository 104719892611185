import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import {
  Page,
  Paper,
  Section
} from '../common/Common';

import AudioPanel from './AudioPanel';
import { audioData } from '../data/AudioData';
import './AudioCollection.css';

class AudioView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: {},
    }
  }

  componentWillMount() {
    this.setState({
      collection: audioData[this.props.match.params.name]
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getPriceText() {
    if (this.state.collection.price === 0.00) {
      return "Download";
    }
    else if (this.state.collection.buyUrl === "") {
      return "Coming Soon"
    }
    else {
      return `$${this.state.collection.price} USD`;
    }
  }

  render() {
    return (
      <Page>
        <Helmet>
          <title>{this.state.collection.title}</title>
          <link rel="canonical" href={`https://hentamine.com/audio/${this.state.collection.title.replace(/\s+/g, '-').toLowerCase()}`} />
          <meta name="description" content={this.state.collection.meta.description} />
        </Helmet>
        <main className="gray-bg">
          <Section>
            <div className="command-bar">
              <div className="breadcrumb-wrapper">
                <h1 className="breadcrumb"><Link to="/audio">Audio</Link> / {this.state.collection.title}</h1>
              </div>
              <a href={this.state.collection.buyUrl} className="link-button primary">{this.getPriceText()}</a>
            </div>
            <div className="audio-grid">
              <div>
                <Paper>
                  <img src={this.state.collection.imgSrc} alt={`${this.state.collection.title} artwork cover.`} />
                  {
                    this.state.collection.description.map((item, i) => {
                      return <p key={i}>{item}</p>
                    })
                  }
                </Paper>
              </div>
              <div>
                <Paper>
                  <dl>
                    <dt>Title</dt>
                    <dd>{this.state.collection.title}</dd>
                    <dt>Price</dt>
                    <dd>{this.state.collection.price === 0 ? "Free" : `$${this.state.collection.price} USD`}</dd>
                    <dt>Released</dt>
                    <dd>{this.state.collection.release}</dd>
                    <dt>Length</dt>
                    <dd>{this.state.collection.time}</dd>
                    <dt>Formats</dt>
                    <dd>{this.state.collection.format}</dd>
                  </dl>
                </Paper>
                {
                  this.state.collection.tracks.length === 0 ?
                    null :
                    <Paper>
                      <AudioPanel tracks={this.state.collection.tracks} />
                    </Paper>
                }
              </div>
            </div>
          </Section>
        </main>
      </Page>
    );
  }
}

export default AudioView;