import React from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../common/Common';

const MinecraftPromo = () => {
  return (
    <Section container="content-grid right" className="gray-bg">
      <div className="content-grid-block">
        <h2>Hentamine. Loves. Minecraft.</h2>
        <p>Upcoming maps, skins, and add-ons.</p>
        <Link className="link-button wide primary" to="/minecraft">Explore</Link>
      </div>
      <img className="content-grid-media" src="https://hentaminecdn.blob.core.windows.net/content/hentamine-loves-minecraft.svg" alt="Love Heart next to a minecraft creeper" />
    </Section>
  );
}

export default MinecraftPromo;