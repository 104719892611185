import React from 'react';
import { Helmet } from 'react-helmet';
import { Page, Section } from '../common/Common';
import './Minecraft.css';

const Minecraft = () => {
  return (
    <Page>
      <Helmet>
        <title>Minecraft | Hentamine</title>
        <link rel="canonical" href="https://hentamine.com/community/minecraft" />
        <meta name="description" content="Check out our upcoming server and maps or download some skins." />
      </Helmet>
      <Section className="gray-bg minecraft-hero">        
        <h1>Server</h1>
        <p>Welcome to Azura, a metropolis run by monarchs and corporations sitting over a crystal clear lake. Make your fortunes through cutthroat business or live a simpler life in the greater Azura region.</p>
        <p>Hentamine presents our own economy and anarchy server. Coming soon for Minecraft: Java Edition.</p>
      </Section>
      <Section className="gray-bg" id="skins">
        <h1 className="title-1">Skins</h1>
        <p>Made for our upcoming server and free to download.</p>
        <div className="minecraft-skin-promo">
          <a href="https://cdn.hentamine.com/minecraft/skins/villager_alex.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/villager_alex.jpg" alt="" />
            <span className="bold">Rural Villager</span>
            <br />
            <span>Alex</span>
          </a>
          <a href="https://cdn.hentamine.com/minecraft/skins/guard_alex.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/capital_guard.jpg" alt="" />
            <span className="bold">Capital Guardsmen.</span>
            <br />
            <span>Alex</span>           
          </a>
          <a href="https://cdn.hentamine.com/minecraft/skins/admiral_steve_hat.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/admiral_steve_hat.jpg" alt="" />
            <span className="bold">Admiral.</span>
            <br />
            <span>Steve</span>            
          </a>
          <a href="https://cdn.hentamine.com/minecraft/skins/admiral_steve_no_hat.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/admiral_steve_nohat.jpg" alt="" />
            <span className="bold">Admiral w/o hat.</span>
            <br />
            <span>Steve</span>            
          </a>
          <a href="https://cdn.hentamine.com/minecraft/skins/admiral_alex_hat.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/admiral_alex_hat.jpg" alt="" />
            <span className="bold">Admiral.</span>
            <br />
            <span>Alex</span>               
          </a>
          <a href="https://cdn.hentamine.com/minecraft/skins/admiral_alex_no_hat.png" download>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/admiral_alex_nohat.jpg" alt="" />
            <span className="bold">Admiral w/o hat.</span>
            <br />
            <span>Alex</span>                
          </a>
          <div>
            <img src="https://cdn.hentamine.com/minecraft/content/skins/masked_bandit_alex.jpg" alt="" />
            <span className="bold">Forest Bandit</span>
            <br />
            <span style={{color: 'red' }}>Coming Soon</span>  
          </div>
        </div>
      </Section>
      <Section className="gray-bg" id="maps">
        <h1>Maps</h1>
        <p>Stay tuned for upcoming maps by following us <a href="https://twitter.com/hentamine">@hentamine</a></p>
      </Section>
    </Page>
  );
}
 
export default Minecraft;