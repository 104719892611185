import React from 'react';
import Container from './Container';
import { SocialLinks } from '../common/Common';

import './Footer.css';

const Footer = (props) => {
  return (
    <footer style={{backgroundColor: 'white'}}>
      <Container className="footer-wrapper">
        <div className="footer-copyright">
          &copy; { new Date().getFullYear() } Hentamine
        </div>
        <SocialLinks/>
      </Container>
    </footer>
   );
}
 
export default Footer;