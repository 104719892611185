import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Page, Section } from '../common/Common';
import ContactForm from './ContactForm';

import './Contact.css';

class Contact extends Component {
  render() { 
    return (
      <Page>
        <Helmet>
          <title>Contact | Hentamine</title>
          <link rel="canonical" href="https://hentamine.com" />
          <meta name="description" content="Have an enquiry about business or a product we sell? Then look no further."/>
        </Helmet>        
        <main>
          <Section className="gray-bg">
            <div className="contact-grid">
              <div className="contact-grid-block">
                <h1 className="title-1">Hello.</h1>
                <p>Feel free to contact us about enquiries or support through the form or the link below.</p>                
                <a className="hyperlink" href="mailto:hello@hentamine.com">hello@hentamine.com</a>
              </div>
              <div className="contact-form-wrapper"><ContactForm /></div> 
            </div>                 
          </Section>
        </main>        
      </Page>
    );
  }
}

export default Contact;