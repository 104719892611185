import React from 'react';

const Play = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24">
      <path d="M8 5v14l11-7z" /><path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}
 
export default Play;